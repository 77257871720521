<template>
    <div class="content_container customer_terms">
        <div class="content">
            <div class="banner">
                <h1>금융소비자보호</h1>
            </div>
			<div class="m_terms_menu_wrapper">
				<ul class="terms_menu">
					<li class="current">
						<a @click="toggleMenu">청약철회권</a>
					</li>
					<li>
						<router-link to="/customer/financeprotect" @click="toggleMenu">금융소비자보호</router-link>
					</li>
					<li>
						<router-link to="/customer/nego" @click="toggleMenu">금리인하요구권</router-link>
					</li>
					<li>
						<router-link to="/customer/dataopen" @click="toggleMenu">자료열람청구권</router-link>
					</li>
					<li>
						<router-link to="/customer/termination" @click="toggleMenu">위법계약해지권</router-link>
					</li>
				</ul>
			</div>
            <section class="terms">
				<ul class="terms_menu">
					<li>
						<router-link to="/customer/financeprotect">금융소비자보호</router-link>
					</li>
					<li>
						<router-link to="/customer/nego">금리인하요구권</router-link>
					</li>
					<li class="current">
						<router-link to="/customer/withdraw">청약철회권</router-link>
					</li>
					<li>
						<router-link to="/customer/dataopen">자료열람청구권</router-link>
					</li>
					<li>
						<router-link to="/customer/termination">위법계약해지권</router-link>
					</li>
				</ul>
				<div>
					<div class="text_box"> 
						<div class="csfont2 title" style="color:#000;">청약철회권</div>

						<br><br>

						<div class="csfont1">1. 청약철회권이란?</div>
						<div class="csfont2">
						일반금융소비자가 대출계약에 대한 숙려기간동안 불이익 없이 대출계약을 철회할 수 있는 권리를 보장해 주는 제도
						</div>
						<br><br>

						<div class="csfont1">2. 청약철회권 주요내용</div>
						<table class="table_terms" border="1">
							<tbody>
								<tr class="em"> 
									<th scope="row" style="">구분</th>
									<th scope="row" style="">내용</th> 
								</tr>
								<tr class="em"> 
									<td style="width:140px;">적용대상</td> 
									<td style="text-align:left;">일반금융소비자(개인 및 개인사업자, 5인 미만 법인, 5인 이상 법인 중 일반 금융 소비자 대우 요청 법인)</td> 
								</tr>
								<tr class="em"> 
									<td style="width:140px;">철회불가대상</td> 
									<td style="text-align:left;">
									- 시설대여·할부금융·연불판매 (금융소비자 보호에 관한 법률 제46조 제1항 제3호에 따른 청약 철회의 기간 이내에 해당 계약에 따른 재화를 제공받은 경우만 해당한다)<br>
									:청약철회기간 내 재화를 제공 받지 않은 경우 청약 철회 가능
									</td>
								</tr>
								<tr class="em"> 
									<td style="width:140px;">절차 및 요건</td> 
									<td style="text-align:left;">
									- 절차 및 요건 > 청약철회권 신청 안내전화(02-2047-7100)<br>
									- 원리금(대출원금+경과이자) 및 금융회사가 부담한 부대비용 상환</td>
								</tr>
								<tr class="em"> 
									<td style="width:140px;">철회효과</td> 
									<td style="text-align:left;">
									- 중도상환수수료 면제<br>
									- 대출정보 삭제(금융회사, 신용정보원, CB사 등)
									</td>
								</tr>
							</tbody> 
						</table>
						<br><br>

						<div class="csfont1">3. 청약철회 시 유의사항</div>
						<div class="csfont2">
						1) 청약철회가 완료된 이후에는 철회취소가 불가능합니다.<br>
						2) 청약철회시 당사 홈페이지에 등재된 별도의 서식을 사용하여 신청하셔야 합니다.<br>
						: 당사 홈페이지 → 고객센터 → 서식자료실 → 청약철회신청
						</div>
						<br><br>

					</div>
				</div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name : 'WithDraw',
	methods:{
		toggleMenu(){
			document.querySelector('.m_terms_menu_wrapper .terms_menu').classList.toggle('on');
		},
	}
}
</script>

